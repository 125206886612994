import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Observable, Subject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class AuthenticationServiceService {

  constructor(private _http: HttpClient) { }

  getToken(BASFUserID) {
    return this._http.get(`${environment.getToken}${BASFUserID}`).pipe(map((response) => response));
 
  }
}
