import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JobGradingService } from '../../../services/job-grading.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  roleValue: any;
  userId = "";
  userInfo: any;
  options: true;
  disableHM = true;
  disableHR = true;
  disableAdmin = true;
  dispHM = false;
  dispHR = false;
  dispAdmin = false;
  
  unAuthorised = false;

  accessData = {
    historyEvaluationAccess: false,
    governanceEvaluationAccess: false
  }
  //public hoverImg: string = '<img src="https://mdbootstrap.com/img/logo/mdb192x192.jpg"/>';
  constructor(private router: Router, private _service: JobGradingService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
  }
  ngOnInit() {

    this.userId = sessionStorage.getItem('userId');
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      this.spinner.show();
      //this.unAuthorised = true;
      this._service.getUserInfo(this.userId).subscribe((response:any[]) => {
        this.userInfo = response;
        if (response.length > 0) {
          if ((this.userInfo[0].roleName != null || this.userInfo[0].roleName != undefined || this.userInfo[0].roleName != "")) {
            ((this.userInfo[0].roleName.indexOf(',') != '-1') ? this.multipleRolesFunc(this.userInfo[0].roleName) : this.singleRole(this.userInfo[0].roleName))
          } else {
            this.roleUnAssignedFunc();
          }
        }
        
        this.spinner.hide();
      })
    } else {
      this.router.navigate(['/login']);
    }
  }

  multipleRolesFunc(roleName) {
    console.log(roleName);
    roleName.indexOf('HiringManager') != '-1' ? this.setHMfalse() : this.disableHM = true;
    roleName.indexOf('HRManager') != '-1' ? this.setHRfalse() : this.disableHR = true;
    roleName.indexOf('Admin') != '-1' ? this.setAdminfalse() : this.disableAdmin = true;
    roleName.indexOf('Readonly') != '-1' ? this.setNonAdminfalse() : this.disableAdmin = true;
  }

  setNonAdminfalse() {
    this.disableHM = false;
    this.dispHM = false;
    sessionStorage.setItem('role', 'Readonly');
    sessionStorage.setItem('historyEvaluationAccess', "true");
    sessionStorage.setItem('governanceEvaluationAccess', "false");
  }

  setHMfalse() {
    this.disableHM = false;
    this.dispHM = true;
    sessionStorage.setItem('role', 'HM');
    sessionStorage.setItem('historyEvaluationAccess', "true");
  }

  setHRfalse() {
    this.disableHR = false;
    this.dispHR = true;
    sessionStorage.setItem('role', 'HR');
    sessionStorage.setItem('historyEvaluationAccess', "false");
  }

  setAdminfalse() {
    this.disableAdmin = false;
    this.dispAdmin = true;
    sessionStorage.setItem('role', 'Admin');
    sessionStorage.setItem('historyEvaluationAccess', "true");
    sessionStorage.setItem('governanceEvaluationAccess', "true");
  }

  singleRole(roleName) {
    let role = "NA";
    if(roleName.indexOf('HiringManager') != '-1') { role = "HM"; this.roleValue = 0;}
    if(roleName.indexOf('HRManager') != '-1') { role = "HR";  this.roleValue = 1;}
    if(roleName.indexOf('Readonly') != '-1') { role = "Readonly";  this.roleValue = 4;}
    if(roleName.indexOf('Admin') != '-1') role = "Admin";
    this.switchRoleFunc(role);
  }

  switchRoleFunc(role) {
    switch(role) {
      case "HM" :  this.disableHM = false;
                   sessionStorage.setItem('historyEvaluationAccess', "true");
                   this.accessData.historyEvaluationAccess = true;
                   this._service.sendUserAccessValues(this.accessData);
                   this.roleValue = "0";
                   sessionStorage.setItem('role', 'HM');
                   this.dispHM = true;
                   break;
      case "HR" :  this.roleValue = "1";
                   sessionStorage.setItem('role', 'HR');
                   sessionStorage.setItem('historyEvaluationAccess', "false");
                   this.accessData.historyEvaluationAccess = false;
                   this._service.sendUserAccessValues(this.accessData);
                   this.disableHR = false;
                   this.dispHR = true;
                   break;
      case "Admin":this.roleValue = "2";
                   sessionStorage.setItem('role', 'Admin');
                   sessionStorage.setItem('historyEvaluationAccess', "true");
                   sessionStorage.setItem('governanceEvaluationAccess', "true");
                   this.accessData.historyEvaluationAccess = true;
                   this.accessData.governanceEvaluationAccess = true;
                   this._service.sendUserAccessValues(this.accessData);
                   this.disableAdmin = false;
                   this.dispAdmin = true;
                   break;
      case "Readonly":this.roleValue = "4";
                   sessionStorage.setItem('role', 'Readonly');
                   sessionStorage.setItem('historyEvaluationAccess', "true");
                   sessionStorage.setItem('governanceEvaluationAccess', "false");
                   this.accessData.historyEvaluationAccess = true;
                   this.accessData.governanceEvaluationAccess = false;
                   this._service.sendUserAccessValues(this.accessData);
                   this.disableAdmin = false;
                   this.dispAdmin = true;
                   break;                   
      case "NA"   :this.roleUnAssignedFunc();
                   break;
    }
  }

  roleUnAssignedFunc() {
    this.toastr.error("Role Not Assigned");
    this.unAuthorised = true;
  }
  
  OnNewPosition() {
    if (this.roleValue == "0") {
      sessionStorage.setItem('role', 'HM');
      sessionStorage.setItem('historyEvaluationAccess', "true");
      this.accessData.historyEvaluationAccess = true;
      this._service.sendUserAccessValues(this.accessData);
      this.router.navigate(['/home/hiring-manager'])
    }
    else if (this.roleValue == "1") {
      sessionStorage.setItem('role', 'HR');
      sessionStorage.setItem('historyEvaluationAccess', "false");
      this.accessData.historyEvaluationAccess = false;
      this._service.sendUserAccessValues(this.accessData);
      this.router.navigate(['/home/hiring-resource'])
    }
    else if (this.roleValue == "2") {
      sessionStorage.setItem('role', 'Admin');
      sessionStorage.setItem('historyEvaluationAccess', "true");
      this.accessData.historyEvaluationAccess = true;
      this._service.sendUserAccessValues(this.accessData);
      this.router.navigate(['/'])
    }
  }
}
